

.angleBox {
    background-color: var(--primary);
    clip-path: polygon(0 0, var(--space-xl) var(--space-xl), 100% var(--space-xl), 100% 100%, 0% 100%);
    margin-top: calc(var(--space-xl) * -1);
    padding: calc(var(--space-xl) * 2) var(--space-xl);
    min-height: 20rem;
}

.angleBox {
    position: relative;
    background-color: var(--primary);
    clip-path: polygon(0% 0%, var(--space-xl) var(--space-xl), calc(100% - var(--space-xl)) var(--space-xl), 100% calc(var(--space-xl) * 2), 100% 100%, 0% 100%);
    margin: calc(var(--space-xl) * -1) 0;
    padding: calc(var(--space-xl) * 2) var(--space-xl) ;
    min-height: 20rem;
}



.triangle {
    position: absolute;
    top: calc( var(--space-xl) * -1);
    left: 0;

    background-color: salmon;
    width: var(--space-xl);
    aspect-ratio: 1/2;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
}
.triangleRight {
    position: absolute;
    z-index: 1;
    top: calc( var(--space-xl) * -1);
    right: 0;

    background-color: salmon;
    width: var(--space-xl);
    aspect-ratio: 1/2;
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
}

.bottom {
    top: auto;
    bottom: calc( var(--space-xl) * -1);
}