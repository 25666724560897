[data-theme='re-2024'] {

    /* colour */

    --primary: #0D978E;
    --secondary: #FFA03B;
    --accent: #E23D4A;

    --bg-global: rgb(255, 255, 255);

    --default-text-color: rgb(71, 71, 71);
    --default-heading-color: var(--primary);

    --wash: #F3F6FB;
    --light: #E6EAF3;
    --medium: #003384;
    --dark: #004C51;


    /* type */

    --default-font-family: 'Space Grotesk', sans-serif;
    --default-font-weight: 200;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* global UI styling */

    --default-radius: 0;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 0;

    --gap-divider-color: currentColor;

    /* header */

    --header-height: 6.875rem;
    --header-height-mobile: 9.5rem;


    /* theme */

    --radar-red: #ff4800;
    --radar-orange: #ffa500;
    --radar-orange-tint-light: #FFEECC;
    --radar-orange-tint-mid: #FFDEA3;
    --radar-orange-tint-dark: #FFBC3A;
    --radar-green-dark: #00514A;
    --radar-green: #1D6547;
    --radar-teal: var(--primary);

    --corner-cut-sm: var(--space-sm);
    --corner-cut-md: var(--space-md);
    --corner-cut-lg: var(--space-lg);
    --corner-cut-xl: var(--space-xl);
    --corner-cut-xxl: calc(var(--space-xl) * 2);


}


/* secondary */

.bg-secondary {
    color: var(--default-text-color);
}
.bg-secondary .formatted h1,
.bg-secondary .formatted h2,
.bg-secondary .formatted h3,
.bg-secondary .formatted h4,
.bg-secondary .formatted h5,
.bg-secondary .formatted h6 {
    color: var(--primary);
}