.hosts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}
.partnerLogo {
    display: block;
    max-width: 10rem;
    max-height: 9rem;
}
.contacts {
    display: grid;
    gap: var(--space-sm);
}
.contactsPhoto {
    width: 10rem;
}

.graphic {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: 8rem;
}

@media(max-width:799px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--space-md);
    }
}

@media(min-width:800px) {
    .container {
        display: grid;
        grid-template-columns: repeat(3, auto);
    }
    .section {
        padding: 0 var(--space-sm);
        border-left: 1px solid var(--primary);
    }
    .contacts {
        grid-template-columns: auto 1fr;
    }
    .contactsPhoto {
        width: 10vw;
    }
    .graphic {
        width: 50vw;
        height: 12.5vw;
        pointer-events: none;
    }
}


@media print {
    .container {display: none}
}